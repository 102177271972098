import React from "react";
import "./Explore.css";

const Explore = () => {
  return (
    <div className="explore-svg">
      <svg
        width="100%"
        height="500"
        viewBox="0 0 934 638"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="undraw_explore_re_8l4v 1">
          <g id="mountains">
            <path
              id="Vector"
              d="M276.039 102C304.205 102 327.039 79.1665 327.039 51C327.039 22.8335 304.205 0 276.039 0C247.872 0 225.039 22.8335 225.039 51C225.039 79.1665 247.872 102 276.039 102Z"
              fill="#F3AB56"
            />
            <path
              id="Vector_2"
              d="M450.76 216.257C442.394 202.421 432.48 187.647 416.955 183.129C398.976 177.897 380.507 188.291 364.841 198.548C317.991 229.222 272.873 262.464 229.694 298.122L229.742 298.659C263.358 296.341 296.974 294.023 330.59 291.705C354.878 290.03 380 288.124 401.463 276.634C409.608 272.274 417.547 266.45 426.777 266.045C438.243 265.54 448.242 273.549 456.099 281.915C502.576 331.408 516.026 407.093 568.169 450.576C535.289 369.488 496.029 291.137 450.76 216.257Z"
              fill="#E2DFDF"
            />
            <path
              id="Vector_3"
              d="M736.45 636.04C731.31 629.551 729.21 628.03 724.14 621.48C682.86 568.091 644.06 512.897 607.74 455.901C583.07 417.191 559.623 377.737 537.4 337.541C526.82 318.431 516.53 299.171 506.53 279.761C498.77 264.711 491.183 249.571 483.77 234.341C481.65 229.991 479.59 225.621 477.54 221.241C472.71 210.941 467.97 200.591 462.88 190.431C457.08 178.851 450.02 166.711 439.37 158.931C434.523 155.245 428.727 153.014 422.659 152.501C413.99 151.941 405.99 155.521 398.47 159.431C343.45 188.121 291.94 224.091 245.35 265.021C198.641 306.042 156.89 352.387 120.95 403.111C119.85 404.681 117.25 403.18 118.36 401.6C122.73 395.42 127.187 389.307 131.73 383.261C188.223 308.127 257.491 243.515 336.369 192.381C349.64 183.781 363.16 175.584 376.93 167.791C383.869 163.861 390.84 159.981 397.93 156.341C404.88 152.771 412.26 149.561 420.2 149.441C447.22 149.021 461.21 179.621 470.82 200.211C473.84 206.691 476.896 213.151 479.99 219.591C491.68 244.04 503.819 268.267 516.409 292.271C524.179 307.091 532.12 321.824 540.23 336.471C565.47 382.081 592.296 426.767 620.71 470.53C658.35 528.5 695.69 579.711 738.57 633.921C739.75 635.421 737.64 637.561 736.45 636.04Z"
              fill="#817A7A"
            />
            <path
              id="Vector_4"
              d="M242.68 249.911C241.11 248.161 239.55 246.411 237.97 244.661C225.51 230.881 212.14 217.261 195.03 209.361C186.871 205.475 177.965 203.41 168.93 203.311C159.59 203.341 150.46 205.791 141.83 209.221C137.75 210.851 133.76 212.701 129.83 214.671C125.34 216.931 120.93 219.341 116.54 221.771C108.3 226.331 100.166 231.091 92.14 236.051C76.1698 245.921 60.6998 256.544 45.7299 267.921C37.9696 273.821 30.3695 279.914 22.9296 286.201C16.0097 292.041 9.22971 298.037 2.58974 304.191C1.16982 305.501 -0.950261 303.381 0.469619 302.071C2.21962 300.441 3.98961 298.821 5.75961 297.221C10.7596 292.701 15.8397 288.267 20.9999 283.921C30.4095 275.981 40.0628 268.347 49.9599 261.021C65.35 249.621 81.2699 238.994 97.7196 229.141C105.94 224.221 114.273 219.507 122.72 215.001C125.27 213.641 127.85 212.311 130.46 211.051C136.382 208.063 142.535 205.559 148.86 203.561C157.819 200.662 167.298 199.731 176.65 200.831C185.679 202.144 194.379 205.154 202.29 209.701C219.09 219.091 232.119 233.611 244.8 247.781C246.08 249.221 243.97 251.351 242.68 249.911Z"
              fill="#817A7A"
            />
            <path
              id="Vector_5"
              d="M586.903 392.708L627.16 377.984L647.147 370.673C653.657 368.293 660.151 365.582 666.88 363.877C672.83 362.37 678.875 362.123 684.741 364.105C689.917 366.02 694.654 368.957 698.672 372.74C702.777 376.52 706.596 380.599 710.098 384.943C714.45 390.173 718.738 395.457 723.044 400.724C740.818 422.461 758.477 444.293 776.018 466.219C793.56 488.145 810.984 510.164 828.291 532.276C845.64 554.442 862.87 576.7 879.98 599.052C882.076 601.79 884.171 604.529 886.263 607.27C887.419 608.784 890.026 607.292 888.854 605.756C871.617 583.18 854.26 560.696 836.783 538.305C819.263 515.86 801.622 493.51 783.86 471.256C766.098 449.002 748.216 426.844 730.214 404.783C725.729 399.287 721.237 393.797 716.737 388.313C712.942 383.687 709.189 379.012 705.026 374.706C697.384 366.803 687.837 359.818 676.409 359.629C669.689 359.517 663.187 361.65 656.944 363.908C650.214 366.343 643.5 368.823 636.778 371.281L596.24 386.108L586.105 389.815C584.307 390.473 585.084 393.373 586.903 392.708V392.708Z"
              fill="#817A7A"
            />
            <path
              id="Vector_6"
              d="M189.732 226.7C169.274 216.186 143.076 218.245 124.277 231.381C160.134 237.567 195.6 245.845 230.491 256.171C216.002 247.68 204.668 234.377 189.732 226.7Z"
              fill="#E2DFDF"
            />
            <path
              id="Vector_7"
              d="M124.075 231.345L120.137 234.546C121.469 233.426 122.854 232.375 124.277 231.381C124.209 231.369 124.142 231.356 124.075 231.345Z"
              fill="#F2F2F2"
            />
            <path
              id="Vector_8"
              d="M697.156 392.855C693.194 388.031 688.909 382.981 682.982 381.021L677.448 381.24C719.574 455.677 776.467 520.72 844.637 572.376C795.477 512.536 746.316 452.695 697.156 392.855Z"
              fill="#F2F2F2"
            />
            <path
              id="Vector_9"
              d="M306.218 553.223C307.133 553.658 308.141 553.858 309.153 553.807C310.164 553.756 311.147 553.454 312.013 552.929C312.456 552.658 312.816 553.38 312.375 553.649C311.416 554.224 310.33 554.556 309.213 554.615C308.095 554.675 306.98 554.459 305.966 553.988C305.866 553.952 305.784 553.879 305.737 553.784C305.689 553.69 305.679 553.581 305.709 553.479C305.743 553.378 305.816 553.295 305.911 553.247C306.006 553.199 306.117 553.19 306.218 553.223Z"
              fill="white"
            />
            <path
              id="Vector_10"
              d="M332.949 589.943C331.325 587.961 330.458 585.467 330.503 582.905C330.549 580.343 331.503 577.88 333.196 575.957C333.54 575.569 334.159 576.086 333.815 576.474C332.235 578.262 331.346 580.556 331.308 582.942C331.271 585.327 332.088 587.648 333.611 589.484C333.942 589.884 333.277 590.34 332.949 589.943V589.943Z"
              fill="white"
            />
            <path
              id="Vector_11"
              d="M322.506 609.441C327.714 610.005 332.942 608.585 337.149 605.465C337.566 605.155 338.024 605.818 337.608 606.127C333.224 609.366 327.78 610.832 322.362 610.234C321.846 610.177 321.993 609.384 322.506 609.441H322.506Z"
              fill="white"
            />
            <path
              id="Vector_12"
              d="M352.549 577.032C353.017 577.93 353.701 578.697 354.54 579.265C355.378 579.833 356.345 580.184 357.352 580.286C357.869 580.337 357.722 581.129 357.208 581.079C356.096 580.961 355.029 580.572 354.101 579.947C353.173 579.321 352.413 578.478 351.886 577.491C351.829 577.402 351.807 577.295 351.826 577.191C351.845 577.086 351.903 576.993 351.988 576.93C352.076 576.87 352.184 576.847 352.289 576.866C352.394 576.885 352.487 576.945 352.549 577.032Z"
              fill="white"
            />
            <path
              id="Vector_13"
              d="M508.463 414.803C510.419 414.598 512.292 413.91 513.916 412.802C515.539 411.693 516.862 410.199 517.765 408.453C518.226 407.556 519.551 408.388 519.094 409.278C518.087 411.203 516.624 412.851 514.833 414.08C513.042 415.309 510.977 416.08 508.819 416.327C508.618 416.367 508.409 416.329 508.234 416.221C508.06 416.112 507.933 415.941 507.88 415.743C507.834 415.541 507.869 415.329 507.978 415.153C508.088 414.977 508.262 414.852 508.463 414.803Z"
              fill="white"
            />
            <path
              id="Vector_14"
              d="M609.307 407.367C610.992 408.38 612.902 408.958 614.865 409.051C616.829 409.143 618.785 408.746 620.557 407.896C621.465 407.457 622.023 408.919 621.121 409.355C619.159 410.286 616.998 410.721 614.828 410.624C612.658 410.526 610.545 409.899 608.674 408.797C608.489 408.708 608.345 408.551 608.271 408.36C608.197 408.168 608.198 407.956 608.275 407.765C608.36 407.576 608.516 407.428 608.709 407.354C608.902 407.279 609.117 407.284 609.307 407.367Z"
              fill="white"
            />
            <path
              id="Vector_15"
              d="M725.514 520.131L781.058 477.961L808.131 505.035L717.646 566.955L725.514 520.131Z"
              fill="#3F3D56"
            />
            <path
              id="Vector_16"
              d="M673.71 636.245H483.708L490.372 614.653C531.674 599.702 508.475 567.565 547.762 547.921C643.857 499.874 681.059 356.495 666.988 299.817L729.961 297.155C729.961 297.155 816.927 544.125 673.71 636.245Z"
              fill="#3F3D56"
            />
            <path
              id="Vector_17"
              d="M830.695 540.84C825.02 545.83 817.759 548.647 810.203 548.788C802.647 548.929 795.286 546.387 789.428 541.612L789.304 541.51L789.3 541.351C789.23 538.706 788.818 536.081 788.073 533.542C788.036 535.37 787.769 537.186 787.279 538.947L787.12 539.54L786.694 539.1C786.325 538.72 785.968 538.333 785.633 537.953C783.662 535.716 782.011 533.216 780.728 530.525C774.584 517.889 765.682 506.793 754.678 498.055C752.714 496.457 750.886 494.698 749.213 492.797C742.148 484.759 738.175 474.468 738.006 463.768C737.836 453.067 741.481 442.656 748.288 434.398C755.094 426.14 764.619 420.574 775.155 418.698C785.691 416.822 796.55 418.757 805.79 424.158L806.071 424.323L805.925 424.614C804.855 426.835 803.442 428.873 801.738 430.654C804.081 429.388 806.249 427.822 808.187 425.995L808.391 425.802L808.623 425.962C811.658 428.061 814.425 430.524 816.862 433.296L817.125 433.597C818.985 435.724 821.273 437.436 823.839 438.62C826.405 439.804 829.192 440.434 832.018 440.469C836.628 440.547 841.168 441.622 845.323 443.622C849.478 445.621 853.151 448.497 856.089 452.051C859.027 455.606 861.16 459.755 862.341 464.212C863.523 468.67 863.724 473.33 862.933 477.873L862.896 478.084L862.691 478.146C859.108 479.377 855.309 479.851 851.533 479.538C854.686 480.911 858.044 481.752 861.472 482.028L861.915 482.063L861.774 482.484C859.989 487.846 856.811 492.637 852.566 496.368L852.445 496.474C852.402 496.511 852.359 496.549 852.316 496.586C849.153 499.31 846.566 502.638 844.706 506.375C842.846 510.111 841.751 514.182 841.485 518.348C841.275 522.626 840.212 526.82 838.359 530.682C836.506 534.544 833.901 537.998 830.695 540.84Z"
              fill="#236129"
            />
            <path
              id="Vector_18"
              opacity="0.15"
              d="M841.729 524.485C841.672 525.635 841.552 526.781 841.37 527.918C844.482 523.139 846.273 517.622 846.559 511.927C846.602 511.055 846.693 510.187 846.816 509.322C843.793 513.829 842.036 519.066 841.729 524.485Z"
              fill="black"
            />
            <path
              id="Vector_19"
              opacity="0.15"
              d="M851.778 485.675C854.313 486.774 856.984 487.529 859.72 487.921C860.774 486.809 861.746 485.622 862.63 484.37C859.139 485.538 855.446 485.982 851.778 485.675Z"
              fill="black"
            />
            <path
              id="Vector_20"
              opacity="0.15"
              d="M863.482 475.19C863.764 477.95 863.688 480.734 863.254 483.475C864.769 481.171 865.977 478.68 866.848 476.064L866.989 475.642L866.546 475.607C865.518 475.519 864.496 475.38 863.482 475.19Z"
              fill="black"
            />
            <path
              id="Vector_21"
              d="M927.751 313.412C927.748 337.746 919.116 361.291 903.389 379.861C887.663 398.43 865.86 410.82 841.858 414.829L841.351 414.912L840.958 414.584C834.414 409.127 827.191 404.542 819.467 400.943C823.807 404.921 827.631 409.427 830.849 414.357L831.943 416.002L829.971 416.097C828.268 416.18 826.576 416.222 824.942 416.222C815.342 416.23 805.788 414.905 796.553 412.285C754.6 400.349 709.55 398.308 662.653 406.218C654.612 407.57 646.472 408.249 638.318 408.249C558.356 408.249 493.302 343.181 493.302 263.203C493.259 232.71 502.842 202.981 520.687 178.254C538.531 153.527 563.726 135.064 592.68 125.496L593.677 125.166L594.07 126.139C597.164 133.448 599.088 141.2 599.769 149.107C601.691 140.75 602.515 132.178 602.218 123.608L602.187 122.703L603.066 122.482C614.597 119.608 626.435 118.156 638.318 118.158L639.605 118.163C648.705 118.191 657.708 116.299 666.029 112.613C674.349 108.927 681.799 103.529 687.893 96.771C697.885 85.7884 710.138 77.1008 723.809 71.3059C737.48 65.511 752.244 62.7464 767.085 63.2023C781.926 63.6582 796.493 67.3237 809.782 73.9467C823.071 80.5696 834.768 89.9929 844.068 101.568L844.501 102.104L844.215 102.732C838.208 115.971 831.664 125.616 823.853 132.67C833.877 127.967 843.052 121.637 851.006 113.934L852.033 112.939L852.753 114.175C861.919 129.894 866.745 147.766 866.738 165.962L866.737 166.481C866.736 166.664 866.736 166.848 866.736 167.031C866.736 194.866 877.499 221.248 896.316 239.495C906.213 249.102 914.092 260.589 919.49 273.282C924.888 285.975 927.696 299.619 927.751 313.412Z"
              fill="#236129"
            />
            <path
              id="Vector_22"
              opacity="0.15"
              d="M802.061 70.4524C798.517 76.3351 794.155 81.6852 789.108 86.343C796.335 82.9498 803.13 78.703 809.347 73.6934C806.962 72.5186 804.533 71.4382 802.061 70.4524Z"
              fill="#AEA97A"
            />
            <path
              id="Vector_23"
              d="M412.473 287.395C406.939 281.432 404.069 272.484 407.012 264.9C411.419 272.571 418.397 278.435 426.712 281.456C429.923 282.609 433.614 283.558 435.367 286.485C436.381 288.378 436.629 290.587 436.06 292.658C435.488 294.704 434.655 296.67 433.583 298.505L433.579 299.035C425.766 296.771 418.007 293.357 412.473 287.395Z"
              fill="#6C63FF"
            />
            <path
              id="Vector_24"
              d="M749.167 549.285C749.864 550.621 750.882 551.763 752.13 552.608C753.378 553.453 754.816 553.975 756.315 554.127C757.084 554.203 756.865 555.382 756.101 555.307C754.445 555.131 752.858 554.553 751.477 553.622C750.097 552.691 748.965 551.437 748.181 549.968C748.096 549.836 748.064 549.676 748.092 549.521C748.12 549.366 748.206 549.227 748.333 549.134C748.464 549.044 748.625 549.009 748.781 549.038C748.937 549.066 749.076 549.155 749.167 549.285Z"
              fill="white"
            />
            <path
              id="Vector_25"
              d="M423.592 559.289L386.779 531.34L368.836 549.283L428.807 590.322L423.592 559.289Z"
              fill="#3F3D56"
            />
            <path
              id="Vector_26"
              d="M457.925 636.245H583.852L579.436 621.935C552.062 612.026 567.438 590.726 541.4 577.707C477.711 545.863 453.055 450.837 462.381 413.273L420.645 411.508C420.645 411.508 363.007 575.191 457.925 636.245Z"
              fill="#3F3D56"
            />
            <path
              id="Vector_27"
              d="M343.269 554.07C343.093 551.309 342.367 548.611 341.135 546.134C339.902 543.658 338.187 541.452 336.091 539.647C336.063 539.622 336.034 539.597 336.006 539.572L335.925 539.502C333.112 537.03 331.006 533.854 329.822 530.301L329.729 530.021L330.023 529.998C332.294 529.816 334.52 529.258 336.61 528.348C334.107 528.555 331.589 528.241 329.215 527.425L329.079 527.384L329.055 527.244C328.53 524.234 328.664 521.145 329.446 518.19C330.229 515.236 331.643 512.487 333.59 510.131C335.537 507.775 337.972 505.869 340.726 504.544C343.48 503.219 346.488 502.506 349.544 502.455C351.417 502.431 353.264 502.014 354.965 501.229C356.665 500.444 358.182 499.31 359.415 497.9L359.589 497.7C361.204 495.863 363.038 494.231 365.049 492.839L365.203 492.734L365.338 492.861C366.623 494.072 368.059 495.11 369.612 495.949C368.483 494.769 367.546 493.418 366.837 491.946L366.74 491.753L366.927 491.644C373.05 488.064 380.248 486.782 387.231 488.025C394.214 489.269 400.526 492.957 405.037 498.43C409.549 503.904 411.964 510.804 411.852 517.896C411.739 524.988 409.106 531.808 404.424 537.135C403.315 538.396 402.104 539.561 400.802 540.621C393.509 546.411 387.609 553.765 383.537 562.14C382.686 563.924 381.592 565.58 380.286 567.063C380.064 567.315 379.827 567.571 379.583 567.823L379.3 568.115L379.195 567.722C378.87 566.555 378.694 565.351 378.669 564.14C378.175 565.823 377.902 567.562 377.856 569.316L377.853 569.421L377.771 569.488C374.735 571.966 371.069 573.552 367.184 574.069C363.299 574.585 359.346 574.012 355.768 572.413C352.19 570.813 349.126 568.251 346.919 565.013C344.712 561.774 343.448 557.985 343.269 554.07Z"
              fill="#236129"
            />
            <path
              id="Vector_28"
              opacity="0.15"
              d="M346.568 562.174C346.606 562.936 346.686 563.696 346.806 564.45C344.744 561.283 343.557 557.626 343.367 553.852C343.339 553.274 343.278 552.698 343.197 552.125C345.201 555.112 346.365 558.583 346.568 562.174Z"
              fill="black"
            />
            <path
              id="Vector_29"
              opacity="0.15"
              d="M339.909 536.453C338.228 537.181 336.458 537.681 334.645 537.941C333.946 537.204 333.302 536.417 332.716 535.588C335.03 536.362 337.477 536.656 339.909 536.453Z"
              fill="black"
            />
            <path
              id="Vector_30"
              opacity="0.15"
              d="M332.152 529.503C331.964 531.332 332.015 533.178 332.302 534.994C331.298 533.467 330.498 531.816 329.92 530.082L329.827 529.803L330.121 529.78C330.802 529.722 331.48 529.629 332.152 529.503Z"
              fill="black"
            />
            <path
              id="Vector_31"
              d="M296.515 351.381C308.986 339.288 316.12 321.803 316.12 303.355C316.12 303.234 316.12 303.111 316.119 302.99L316.118 302.646C316.113 290.586 319.312 278.742 325.387 268.324L325.864 267.504L326.545 268.164C331.816 273.269 337.897 277.465 344.541 280.581C339.364 275.906 335.027 269.514 331.046 260.74L330.856 260.323L331.143 259.968C337.307 252.296 345.059 246.051 353.867 241.662C362.674 237.272 372.328 234.843 382.165 234.541C392.001 234.238 401.786 236.071 410.846 239.911C419.907 243.752 428.028 249.51 434.651 256.789C438.69 261.268 443.627 264.846 449.141 267.289C454.656 269.731 460.623 270.985 466.654 270.967L467.507 270.963C475.382 270.962 483.229 271.925 490.87 273.829L491.453 273.975L491.433 274.575C491.236 280.255 491.782 285.937 493.056 291.475C493.508 286.234 494.782 281.097 496.833 276.253L497.094 275.608L497.755 275.827C519.43 283.02 537.838 297.694 549.681 317.221C561.524 336.748 566.029 359.854 562.39 382.4C558.751 404.946 547.205 425.461 529.82 440.27C512.434 455.08 490.345 463.217 467.507 463.225C462.103 463.225 456.708 462.775 451.378 461.88C420.297 456.637 390.439 457.99 362.635 465.901C356.514 467.637 350.181 468.515 343.819 468.509C342.736 468.509 341.615 468.482 340.486 468.427L339.179 468.364L339.904 467.274C342.037 464.006 344.571 461.02 347.447 458.383C342.329 460.768 337.541 463.807 333.205 467.424L332.944 467.641L332.608 467.586C320.158 465.515 308.53 460.026 299.019 451.73C289.508 443.435 282.489 432.66 278.746 420.608C275.003 408.555 274.682 395.7 277.82 383.476C280.957 371.252 287.43 360.14 296.515 351.381Z"
              fill="#236129"
            />
            <path
              id="Vector_32"
              d="M48.4641 562.61C37.4522 562.609 26.7975 558.703 18.3944 551.586C9.99128 544.469 4.38428 534.603 2.57031 523.741L2.53281 523.511L2.681 523.334C5.15055 520.373 7.22557 517.104 8.85413 513.609C7.05404 515.573 5.01467 517.303 2.78382 518.759L2.03925 519.254L1.99631 518.362C1.95881 517.591 1.94005 516.825 1.94005 516.086C1.9363 511.742 2.53579 507.418 3.72135 503.239C9.12268 484.255 10.0463 463.868 6.46677 442.646C5.85514 439.007 5.54781 435.324 5.54799 431.634C5.55349 416.041 11.1096 400.958 21.2213 389.088C31.3331 377.217 45.3401 369.334 60.7344 366.849C76.1286 364.364 91.9047 367.44 105.238 375.527C118.571 383.613 128.59 396.181 133.501 410.981L133.65 411.433L133.21 411.611C129.902 413.011 126.395 413.881 122.816 414.189C126.598 415.059 130.477 415.432 134.356 415.298L134.765 415.284L134.865 415.682C136.165 420.899 136.822 426.257 136.822 431.634L136.819 432.216C136.807 436.334 137.663 440.409 139.331 444.174C140.999 447.939 143.442 451.31 146.5 454.068C151.47 458.59 155.401 464.134 158.024 470.321C160.646 476.507 161.897 483.188 161.691 489.904C161.484 496.62 159.826 503.212 156.829 509.226C153.831 515.24 149.567 520.533 144.329 524.741L144.087 524.937L143.802 524.808C137.811 522.089 133.447 519.128 130.255 515.593C132.383 520.129 135.248 524.281 138.733 527.881L139.184 528.345L138.624 528.671C131.511 532.819 123.423 535.003 115.189 535L114.954 534.999C114.872 534.999 114.788 534.999 114.705 534.999C108.624 534.935 102.59 536.086 96.9594 538.384C91.3284 540.683 86.2132 544.083 81.9137 548.385C77.5661 552.863 72.3678 556.429 66.6239 558.871C60.8799 561.314 54.7059 562.585 48.4641 562.61Z"
              fill="#236129"
            />
            <path
              id="Vector_33"
              d="M31.9996 635.914C31.7976 635.914 31.6024 635.84 31.4499 635.708C31.2974 635.575 31.1978 635.392 31.1694 635.192C31.1337 634.942 27.6395 609.741 30.7777 576.248C33.6758 545.317 42.9829 500.765 70.8632 464.175C70.93 464.088 71.0135 464.014 71.1088 463.958C71.2041 463.903 71.3095 463.867 71.4188 463.852C71.5282 463.837 71.6393 463.844 71.746 463.872C71.8527 463.9 71.9528 463.949 72.0406 464.016C72.1283 464.083 72.2021 464.166 72.2575 464.262C72.313 464.357 72.3491 464.463 72.3638 464.572C72.3785 464.681 72.3716 464.792 72.3433 464.899C72.315 465.006 72.266 465.106 72.199 465.193C44.5595 501.467 35.3274 545.692 32.4497 576.405C29.3298 609.703 32.7966 634.707 32.832 634.956C32.8488 635.075 32.8399 635.196 32.8057 635.312C32.7716 635.427 32.7131 635.534 32.6342 635.625C32.5553 635.715 32.4578 635.788 32.3483 635.838C32.2388 635.888 32.1199 635.914 31.9996 635.914Z"
              fill="#3F3D56"
            />
            <path
              id="Vector_34"
              d="M45.9369 512.557C45.7595 512.558 45.5867 512.501 45.4431 512.397C45.2995 512.293 45.1927 512.146 45.1378 511.978C45.0829 511.809 45.0828 511.627 45.1376 511.458C45.1923 511.29 45.2991 511.143 45.4426 511.038C53.3868 505.661 61.9688 501.292 70.9912 498.033C85.0868 492.872 106.521 488.194 128.588 495.217C128.693 495.25 128.791 495.304 128.876 495.375C128.96 495.446 129.03 495.533 129.081 495.631C129.132 495.729 129.163 495.836 129.172 495.946C129.182 496.056 129.169 496.167 129.136 496.272C129.102 496.378 129.048 496.475 128.977 496.56C128.906 496.644 128.818 496.714 128.72 496.764C128.622 496.815 128.515 496.845 128.405 496.854C128.295 496.864 128.184 496.851 128.079 496.817C106.464 489.938 85.4182 494.539 71.5686 499.61C62.6913 502.814 54.2469 507.109 46.4298 512.397C46.2866 512.501 46.114 512.557 45.9369 512.557Z"
              fill="#3F3D56"
            />
            <path
              id="Vector_35"
              d="M135.938 477.713C136.908 477.449 137.808 476.975 138.575 476.326C139.341 475.676 139.956 474.866 140.376 473.953C140.796 473.04 141.011 472.046 141.005 471.041C140.999 470.036 140.773 469.044 140.343 468.136L155.158 419.836L144.261 417.676L130.711 465.02C129.233 465.845 128.109 467.182 127.552 468.78C126.995 470.377 127.043 472.124 127.687 473.689C128.331 475.253 129.527 476.527 131.048 477.269C132.569 478.01 134.309 478.169 135.938 477.713Z"
              fill="#F8ECD1"
            />
            <path
              id="Vector_36"
              d="M156.021 405.725C154.448 405.101 152.717 404.996 151.081 405.425V405.435C150.529 405.567 149.995 405.765 149.491 406.025C148.938 406.297 148.42 406.636 147.951 407.035C146.935 407.878 146.151 408.965 145.671 410.195L138.451 422.775C138.243 423.132 138.116 423.53 138.078 423.941C138.04 424.352 138.092 424.766 138.231 425.155C138.366 425.546 138.585 425.903 138.871 426.202C139.158 426.5 139.506 426.733 139.891 426.885L141.451 427.495L148.861 430.405L151.591 431.485L153.431 432.205C153.815 432.357 154.228 432.424 154.641 432.401C155.054 432.379 155.457 432.267 155.822 432.074C156.188 431.882 156.507 431.612 156.759 431.284C157.011 430.956 157.189 430.578 157.281 430.175L160.541 416.085C161.312 414.112 161.269 411.913 160.422 409.971C159.575 408.029 157.992 406.502 156.021 405.725Z"
              fill="#3F3D56"
            />
            <path
              id="Vector_37"
              d="M177.492 623.065H185.036L188.626 593.969H177.492V623.065Z"
              fill="#F8ECD1"
            />
            <path
              id="Vector_38"
              d="M173.511 636.135H178.921L179.886 631.028L182.359 636.135H196.707C197.395 636.135 198.064 635.915 198.618 635.508C199.172 635.1 199.582 634.526 199.786 633.87C199.991 633.213 199.98 632.508 199.756 631.858C199.532 631.208 199.106 630.646 198.54 630.256L187.082 622.343V617.179L175.031 617.899L173.511 636.135Z"
              fill="#2F2E41"
            />
            <path
              id="Vector_39"
              d="M104.13 608.098L110.618 611.946L128.548 588.751L118.972 583.072L104.13 608.098Z"
              fill="#F8ECD1"
            />
            <path
              id="Vector_40"
              d="M94.0387 617.309L98.6916 620.069L102.127 616.168L101.649 621.823L113.99 629.141C114.582 629.492 115.27 629.645 115.954 629.577C116.638 629.509 117.283 629.224 117.794 628.764C118.305 628.303 118.656 627.692 118.794 627.018C118.933 626.344 118.853 625.644 118.565 625.019L112.747 612.369L115.381 607.927L104.648 602.399L94.0387 617.309Z"
              fill="#2F2E41"
            />
            <path
              id="Vector_41"
              d="M180.859 437.027L184.525 460.396L149.7 460.855L154.283 438.402L180.859 437.027Z"
              fill="#F8ECD1"
            />
            <path
              id="Vector_42"
              d="M184.659 460.101L194.606 484.224L195.33 548.734L186.842 610.327H177.449L173.6 549.738L164.134 508.28L154.798 551.64L125.77 594.082H111.505L137.73 547.313L140.765 496.366C140.765 496.366 138.874 474.754 148.555 462L150.912 455.192L184.659 460.101Z"
              fill="#D1E6F8"
            />
            <path
              id="Vector_43"
              d="M185.141 415.505C185.121 415.295 185.091 415.075 185.051 414.865C184.298 409.823 181.578 405.284 177.487 402.242C173.396 399.201 168.266 397.904 163.221 398.635C158.483 399.345 154.166 401.759 151.081 405.425V405.435C150.921 405.625 150.771 405.825 150.621 406.025C150.351 406.365 150.101 406.725 149.861 407.085C147.693 410.36 146.577 414.219 146.661 418.145V418.155C146.692 419.668 146.904 421.172 147.291 422.635C147.957 425.194 148.481 427.788 148.861 430.405C149.121 432.065 149.321 433.695 149.471 435.285C149.561 436.085 149.631 436.875 149.691 437.645V437.655C149.991 441.465 150.091 444.885 150.181 447.535C150.221 448.975 150.261 450.175 150.331 451.095C150.333 451.156 150.339 451.216 150.351 451.275C150.428 451.893 150.693 452.473 151.111 452.935C151.162 453 151.219 453.06 151.281 453.115C151.724 453.514 152.261 453.796 152.841 453.935C153.042 453.975 153.246 453.995 153.451 453.995H153.511L159.341 451.275L163.941 449.135L179.551 441.855C180.191 441.842 180.811 441.634 181.329 441.259C181.848 440.883 182.239 440.359 182.451 439.755V439.745C182.471 439.665 182.501 439.585 182.521 439.495C183.361 436.185 184.851 432.635 185.381 426.925C185.451 426.105 185.511 425.255 185.541 424.345V424.335C185.629 421.386 185.495 418.434 185.141 415.505Z"
              fill="#D1E6F8"
            />
            <path
              id="Vector_44"
              d="M163.461 395.473C171.593 395.473 178.186 388.881 178.186 380.749C178.186 372.616 171.593 366.024 163.461 366.024C155.329 366.024 148.737 372.616 148.737 380.749C148.737 388.881 155.329 395.473 163.461 395.473Z"
              fill="#F8ECD1"
            />
            <path
              id="Vector_45"
              d="M147.741 403.635C144.639 403.639 141.665 404.873 139.472 407.066C137.279 409.26 136.045 412.233 136.041 415.335V439.585C136.04 442.311 136.992 444.952 138.732 447.05C140.473 449.148 142.892 450.572 145.571 451.075H145.581C146.293 451.208 147.016 451.275 147.741 451.275H163.941V403.635H147.741Z"
              fill="#070249"
            />
            <path
              id="Vector_46"
              d="M147.951 406.025V423.715C147.951 424.256 148.166 424.775 148.548 425.158C148.931 425.54 149.45 425.755 149.991 425.755C150.532 425.755 151.051 425.54 151.433 425.158C151.816 424.775 152.031 424.256 152.031 423.715V406.025H147.951Z"
              fill="#3F3D56"
            />
            <path
              id="Vector_47"
              d="M156.941 435.285H145.081C144.743 435.285 144.408 435.352 144.095 435.482C143.783 435.612 143.499 435.802 143.26 436.042C143.022 436.281 142.833 436.566 142.704 436.879C142.575 437.192 142.51 437.527 142.511 437.865V444.955C142.51 445.638 142.781 446.293 143.262 446.776C143.744 447.26 144.398 447.533 145.081 447.535H156.941C157.625 447.533 158.28 447.261 158.763 446.777C159.247 446.294 159.519 445.639 159.521 444.955V437.865C159.521 437.526 159.454 437.191 159.325 436.878C159.195 436.565 159.005 436.28 158.765 436.041C158.526 435.801 158.241 435.611 157.928 435.481C157.615 435.352 157.28 435.285 156.941 435.285Z"
              fill="#3F3D56"
            />
            <path
              id="Vector_48"
              d="M178.85 433.042C182.084 428.937 183.792 423.835 183.68 418.61C183.68 408.103 177.421 399.556 169.729 399.556C165.599 399.556 161.708 402.024 159.054 406.327L160.213 407.042C162.613 403.149 166.082 400.917 169.729 400.917C176.671 400.917 182.319 408.854 182.319 418.61C182.432 423.513 180.839 428.304 177.811 432.162L178.85 433.042Z"
              fill="#070249"
            />
            <path
              id="Vector_49"
              d="M210.937 468.619C211.382 467.718 211.624 466.731 211.646 465.726C211.668 464.721 211.47 463.724 211.065 462.804C210.66 461.884 210.058 461.065 209.302 460.402C208.546 459.74 207.654 459.252 206.689 458.972L180.357 415.856L171.515 422.58L197.97 464.116C197.607 465.769 197.863 467.497 198.689 468.973C199.515 470.45 200.855 471.572 202.453 472.127C204.051 472.682 205.798 472.632 207.361 471.985C208.925 471.339 210.197 470.141 210.937 468.619Z"
              fill="#F8ECD1"
            />
            <path
              id="Vector_50"
              d="M189.501 421.115L185.051 414.865L182.671 411.525L181.121 409.345C180.634 408.385 179.957 407.535 179.131 406.845C177.721 405.665 175.946 405.006 174.107 404.981C172.268 404.956 170.477 405.565 169.035 406.707C167.593 407.848 166.588 409.452 166.19 411.248C165.792 413.043 166.026 414.921 166.851 416.565L171.471 430.315C171.604 430.706 171.82 431.065 172.104 431.365C172.388 431.666 172.733 431.902 173.116 432.057C173.5 432.213 173.912 432.284 174.325 432.267C174.738 432.249 175.142 432.143 175.511 431.955L177.001 431.195L179.351 429.995L181.461 428.925H181.471L185.381 426.925L186.711 426.255L188.471 425.355C188.838 425.165 189.161 424.898 189.416 424.573C189.672 424.248 189.854 423.872 189.952 423.47C190.05 423.068 190.06 422.65 189.982 422.243C189.904 421.837 189.74 421.452 189.501 421.115Z"
              fill="#D1E6F8"
            />
            <path
              id="Vector_51"
              d="M154.481 368.735V368.745C154.482 369.386 154.401 370.024 154.238 370.643C154.212 370.752 154.181 370.866 154.145 370.974V370.98C153.798 372.086 153.199 373.097 152.396 373.933C151.593 374.77 150.607 375.409 149.515 375.8C149.355 375.862 149.189 375.914 149.024 375.961C148.675 376.062 148.319 376.134 147.958 376.178C147.635 376.224 147.308 376.246 146.981 376.245C146.725 376.246 146.47 376.234 146.215 376.209C145.62 376.149 145.035 376.015 144.472 375.811C144.384 375.785 144.298 375.754 144.214 375.717C144.171 375.702 144.13 375.685 144.089 375.666C143.151 375.273 142.302 374.694 141.595 373.963C140.887 373.232 140.336 372.365 139.973 371.414C139.611 370.464 139.446 369.45 139.487 368.433C139.529 367.417 139.776 366.42 140.215 365.502L140.221 365.497C140.588 364.727 141.087 364.027 141.695 363.428C143.098 362.028 144.999 361.243 146.981 361.245C147.079 361.243 147.178 361.247 147.276 361.255C148.417 361.303 149.533 361.609 150.538 362.151C151.544 362.693 152.413 363.457 153.079 364.385C153.656 365.185 154.068 366.092 154.289 367.054C154.416 367.605 154.481 368.169 154.481 368.735Z"
              fill="#FDEE87"
            />
            <path
              id="Vector_52"
              d="M145.826 364.186C145.166 361.701 143.741 359.487 141.753 357.857C139.765 356.227 137.314 355.264 134.747 355.105C132.181 354.946 129.63 355.598 127.456 356.97C125.281 358.342 123.594 360.364 122.632 362.748C121.021 366.745 121.612 371.28 120.647 375.479C119.674 379.292 117.477 382.681 114.394 385.127C111.31 387.572 107.51 388.939 103.575 389.017L118.173 394.899C119.731 395.696 121.479 396.05 123.225 395.922C125.765 395.492 127.532 393.066 128.248 390.591C128.963 388.116 128.905 385.488 129.358 382.952C129.946 379.836 131.285 376.911 133.258 374.429C135.232 371.948 137.78 369.985 140.684 368.711C142.327 368.008 144.154 367.479 145.316 366.121C146.478 364.763 146.413 362.202 144.729 361.603"
              fill="#FDEE87"
            />
            <path
              id="Vector_53"
              d="M169.481 364.245C167.501 359.918 162.276 360.799 157.552 361.369C155.193 361.733 152.959 362.667 151.042 364.089C149.125 365.512 147.584 367.38 146.552 369.532C144.533 373.894 144.147 378.836 145.465 383.458C146.171 386.607 147.858 389.45 150.282 391.579C152.777 393.586 164.505 398.331 165.481 394.245C164.26 399.575 166.716 398.741 170.35 402.827C172.233 397.172 173.115 386.517 174.998 380.862L175.848 378.308C176.445 376.514 177.043 374.717 177.475 372.876C178.017 370.563 178.226 367.91 176.789 366.018C175.352 364.127 171.733 364.053 171.007 366.315"
              fill="#FDEE87"
            />
            <path
              id="Vector_54"
              d="M167.11 382.252L166.481 378.245C166.909 373.262 166.21 365.335 169.481 364.245L171.481 366.245C170.216 366.667 167.809 374.119 167.11 382.252Z"
              fill="#FA4F8C"
            />
          </g>
          <g id="clouds2">
            <path
              id="cloud2"
              d="M368.074 11.8704C343.357 8.52946 315.139 21.8891 308.733 45.9939C307.118 41.7081 304.183 38.0464 300.352 35.538C296.52 33.0295 291.99 31.8041 287.416 32.0388C282.842 32.2735 278.461 33.9561 274.906 36.8437C271.352 39.7312 268.807 43.674 267.639 48.1026L270.469 50.1293C323.437 61.5836 378.266 61.3357 431.128 49.4029C413.935 31.334 392.791 15.2113 368.074 11.8704Z"
              fill="#D9F6FF"
            />
            <path
              id="cloud2_2"
              d="M501.435 50.5074C476.718 47.1665 448.5 60.5261 442.094 84.6309C440.479 80.3451 437.544 76.6834 433.712 74.1749C429.88 71.6665 425.35 70.4411 420.777 70.6758C416.203 70.9105 411.822 72.5931 408.267 75.4807C404.712 78.3682 402.167 82.311 401 86.7396L403.83 88.7663C456.797 100.221 511.627 99.9727 564.488 88.0399C547.296 69.971 526.152 53.8483 501.435 50.5074Z"
              fill="#D9F6FF"
            />
          </g>
          <g id="clouds1">
            <path
              id="cloud1"
              d="M230.435 74.5074C205.718 71.1665 177.5 84.5261 171.094 108.631C169.479 104.345 166.544 100.683 162.712 98.1749C158.88 95.6665 154.35 94.4411 149.777 94.6758C145.203 94.9105 140.822 96.5931 137.267 99.4807C133.712 102.368 131.167 106.311 130 110.74L132.83 112.766C185.797 124.221 240.627 123.973 293.489 112.04C276.296 93.971 255.152 77.8483 230.435 74.5074Z"
              fill="#D9F6FF"
            />
            <path
              id="cloud1_2"
              d="M123.435 27.5074C98.718 24.1665 70.4997 37.5261 64.0935 61.6309C62.4791 57.3451 59.5439 53.6834 55.7122 51.1749C51.8804 48.6665 47.3504 47.4411 42.7766 47.6758C38.2027 47.9105 33.8219 49.5931 30.2671 52.4807C26.7122 55.3682 24.1673 59.311 23 63.7396L25.8297 65.7663C78.7972 77.2206 133.627 76.9727 186.489 65.0399C169.296 46.971 148.152 30.8483 123.435 27.5074Z"
              fill="#D9F6FF"
            />
          </g>
        </g>
      </svg>
    </div>
  );
};

export default Explore;
