import React from "react";
import "./Camping.css";

const Camping = () => {
  return (
    <div className="camping-svg">
      <svg
        width="100%"
        height="500px"
        viewBox="0 0 940 727"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="undraw_camping_noc8 1">
          <g id="woman-toasting">
            <path
              id="Vector"
              d="M834.255 593.77L664.695 587.77L575.275 596.3L422.505 610.87L365.015 616.35L231.875 660.75C231.341 660.928 230.767 660.951 230.219 660.816C229.672 660.681 229.175 660.394 228.784 659.988C228.394 659.582 228.127 659.073 228.013 658.521C227.9 657.97 227.946 657.397 228.145 656.87L228.155 656.86L251.245 595.79L248.815 594L185.275 547.31L184.935 547.06L102.545 534.5C101.385 534.322 100.29 533.851 99.3619 533.133C98.4342 532.414 97.7048 531.471 97.2425 530.392C96.7803 529.314 96.6004 528.135 96.7198 526.968C96.8392 525.8 97.254 524.683 97.925 523.72L223.405 343.66L289.345 249.06L296.495 238.79L570.795 235L838.605 585.35C839.208 586.139 839.575 587.083 839.662 588.072C839.749 589.062 839.553 590.056 839.097 590.938C838.641 591.82 837.944 592.555 837.087 593.056C836.23 593.558 835.248 593.805 834.255 593.77V593.77Z"
              fill="#95C2DB"
            />
            <path
              id="Vector_2"
              opacity="0.2"
              d="M364.244 249.587L252.307 590.185L188.774 543.495L226.902 339.847L292.65 245.019L292.837 245.24L364.244 249.587Z"
              fill="black"
            />
            <path
              id="Vector_3"
              d="M264.326 380.065C251.625 410.598 247.128 436.102 232.063 431.496C211.437 425.191 233.093 402.889 245.794 372.356C258.496 341.822 267.47 318.4 278.058 320.924C297.797 325.629 277.027 349.531 264.326 380.065Z"
              fill="#3F3D56"
            />
            <path
              id="Vector_4"
              d="M305.204 322.006C305.201 319.985 304.799 317.985 304.023 316.119C303.247 314.253 302.111 312.559 300.679 311.132C299.248 309.706 297.55 308.575 295.682 307.805C293.814 307.034 291.812 306.639 289.791 306.643C289.444 306.643 289.101 306.659 288.759 306.683L274.239 304.891C272.572 304.297 270.816 303.996 269.047 304C268.558 304.001 268.076 304.027 267.599 304.072L267.04 304.003L267.025 304.14C263.617 304.597 260.461 306.183 258.059 308.643C255.658 311.104 254.15 314.298 253.775 317.716C253.401 321.134 254.182 324.579 255.995 327.501C257.807 330.423 260.545 332.654 263.773 333.838L263.743 334.115L265.172 334.275C266.453 334.61 267.772 334.778 269.096 334.775C269.243 334.775 269.388 334.768 269.534 334.764L284.379 336.426C286.124 337.086 287.975 337.422 289.841 337.418C290.536 337.416 291.231 337.367 291.92 337.271L293.186 337.413L293.194 337.042C296.606 336.274 299.655 334.366 301.839 331.633C304.022 328.899 305.209 325.504 305.204 322.006V322.006Z"
              fill="#1F3643"
              fill-opacity="0.5"
            />
            <path
              id="Vector_5"
              d="M87.5975 529.153L85.8201 528.22C131.345 441.451 260.105 256.058 261.4 254.196L263.048 255.342C261.754 257.203 133.064 442.494 87.5975 529.153Z"
              fill="#3F3D56"
            />
            <path
              id="Vector_6"
              d="M227.046 659.188L225.109 658.66C226.047 655.212 319.295 313.17 351.671 255.471L353.421 256.453C321.166 313.938 227.983 655.742 227.046 659.188Z"
              fill="#3F3D56"
            />
            <path
              id="Vector_7"
              d="M849.991 592.874C800.279 510.982 612.544 257.357 610.653 254.804L612.266 253.61C614.157 256.163 801.947 509.862 851.706 591.833L849.991 592.874Z"
              fill="#3F3D56"
            />
            <path
              id="Vector_8"
              d="M223.432 668.292C227.312 668.285 230.451 664.237 230.443 659.248C230.435 654.26 227.283 650.222 223.403 650.228C219.523 650.234 216.385 654.283 216.393 659.271C216.401 664.259 219.553 668.298 223.432 668.292Z"
              fill="#3F3D56"
            />
            <path
              id="Vector_9"
              d="M88.0539 533.033C91.9336 533.027 95.0722 528.978 95.0642 523.99C95.0562 519.001 91.9046 514.963 88.0248 514.969C84.1451 514.975 81.0065 519.024 81.0145 524.012C81.0226 529 84.1742 533.039 88.0539 533.033Z"
              fill="#3F3D56"
            />
            <path
              id="Vector_10"
              d="M851.853 597.381C855.732 597.375 858.871 593.326 858.863 588.338C858.855 583.35 855.703 579.311 851.824 579.317C847.944 579.324 844.805 583.373 844.813 588.361C844.821 593.349 847.973 597.388 851.853 597.381Z"
              fill="#3F3D56"
            />
            <path
              id="Vector_11"
              d="M253.599 264.158L632.22 268.215C631.957 237.292 619.484 207.726 597.52 185.958C575.555 164.19 545.878 151.985 514.954 152L370.458 152.232C310.019 152.33 258.843 199.594 253.95 259.835L253.599 264.158Z"
              fill="#1F3643"
            />
            <path
              id="Vector_12"
              opacity="0.2"
              d="M586.269 389.314H447.889C446.204 389.313 444.536 389.646 442.981 390.295C441.426 390.943 440.015 391.894 438.831 393.093C437.647 394.291 436.712 395.712 436.082 397.275C435.452 398.837 435.138 400.51 435.159 402.194L435.279 412.314L436.509 520.654L436.529 522.744L436.569 526.194L436.609 529.564L436.839 549.874C436.877 553.179 438.198 556.34 440.523 558.689C442.848 561.039 445.995 562.392 449.299 562.464C449.639 562.464 449.989 562.454 450.339 562.434L483.199 560.434L530.739 557.534L547.519 556.514L551.259 556.284L577.409 554.694H577.419L587.039 554.104C590.275 553.903 593.313 552.478 595.535 550.116C597.757 547.755 598.995 544.636 598.999 541.394V402.044C598.996 398.669 597.653 395.433 595.267 393.047C592.88 390.66 589.644 389.318 586.269 389.314V389.314Z"
              fill="black"
            />
            <path
              id="Vector_13"
              d="M614.766 371.619C614.265 371.659 613.78 371.809 613.344 372.059C612.909 372.309 612.534 372.652 612.247 373.063L596.999 373.088V369.044C596.996 365.669 595.653 362.433 593.267 360.047C590.88 357.66 587.644 356.318 584.269 356.314H445.889C444.205 356.313 442.536 356.646 440.981 357.295C439.426 357.943 438.016 358.894 436.831 360.092C435.647 361.291 434.712 362.712 434.082 364.275C433.452 365.837 433.138 367.51 433.159 369.194L433.209 373.352L423.375 373.367C422.836 372.793 422.096 372.45 421.31 372.411C417.864 372.416 415.082 379.943 415.097 389.222C415.112 398.501 417.918 406.019 421.364 406.013C421.965 405.948 422.541 405.739 423.043 405.404C423.546 405.069 423.961 404.618 424.253 404.089L612.296 403.786C612.585 404.197 612.961 404.539 613.397 404.787C613.833 405.035 614.319 405.184 614.82 405.222C618.266 405.217 621.048 397.69 621.033 388.411C621.018 379.131 618.212 371.614 614.766 371.619Z"
              fill="#1F3643"
              fill-opacity="0.5"
            />
            <path
              id="Vector_14"
              d="M599.275 584.058C598.359 584.915 597.257 585.549 596.055 585.909C594.853 586.269 593.585 586.347 592.348 586.136C591.112 585.924 589.941 585.43 588.927 584.691C587.913 583.952 587.084 582.988 586.505 581.875L578.981 567.431L581.975 560.946L598.103 571.268C599.146 571.936 600.024 572.833 600.667 573.892C601.311 574.951 601.705 576.142 601.818 577.376C601.931 578.61 601.761 579.853 601.32 581.011C600.879 582.169 600.18 583.212 599.275 584.058Z"
              fill="#FFC9A9"
            />
            <path
              id="Vector_15"
              d="M631.772 668.335C631.594 666.666 630.799 665.124 629.542 664.01C628.286 662.897 626.66 662.293 624.982 662.316C623.453 662.306 621.965 662.809 620.757 663.746C619.548 664.683 618.69 665.998 618.319 667.482V667.482L611.656 699.836C611.436 700.782 611.322 701.749 611.316 702.72C611.316 710.016 617.422 715.086 624.982 715.086C632.541 715.086 638.651 710.016 638.651 702.72C638.647 701.551 638.488 700.388 638.179 699.261L631.772 668.335Z"
              fill="#3F3D56"
            />
            <path
              id="Vector_16"
              d="M682.17 682.806C681.493 682.212 680.764 681.679 679.992 681.213L654.539 663.425C652.06 661.87 649.414 661.914 647.492 663.792C646.653 664.59 646.12 665.657 645.986 666.807C645.853 667.958 646.126 669.118 646.76 670.088L665.979 695.347C666.418 695.96 666.907 696.534 667.443 697.064C670.774 700.787 678.162 700.992 682.222 697.064C686.226 693.07 686.179 685.954 682.17 682.806Z"
              fill="#3F3D56"
            />
            <path
              id="Vector_17"
              d="M701.298 656.455H675.519C674.692 656.424 673.887 656.72 673.278 657.28C672.669 657.839 672.306 658.617 672.268 659.443C672.255 660.204 672.497 660.948 672.956 661.556C673.415 662.164 674.065 662.6 674.801 662.796L699.12 670.319C702.652 671.142 707.411 667.994 707.411 664.062C707.411 659.062 704.629 656.455 701.298 656.455Z"
              fill="#3F3D56"
            />
            <path
              id="Vector_18"
              d="M578.633 659.428C578.606 659.003 578.494 658.588 578.305 658.207C578.116 657.826 577.852 657.486 577.53 657.208C577.208 656.93 576.834 656.719 576.429 656.587C576.024 656.455 575.597 656.405 575.173 656.441H549.394C546.147 656.441 543.409 659.948 543.409 663.879C543.409 667.811 548.028 671.142 551.572 670.319L576.257 662.796C576.997 662.624 577.648 662.185 578.086 661.564C578.524 660.943 578.719 660.183 578.633 659.428Z"
              fill="#3F3D56"
            />
            <path
              id="Vector_19"
              d="M596.325 663.455L570.864 681.166C570.074 681.61 569.337 682.145 568.668 682.758C566.796 684.499 565.692 686.912 565.6 689.467C565.507 692.022 566.433 694.509 568.174 696.38C568.333 696.551 568.497 696.715 568.668 696.874C570.665 698.76 573.308 699.811 576.055 699.811C578.802 699.811 581.446 698.76 583.443 696.874C583.987 696.353 584.478 695.779 584.907 695.161L604.141 670.044C604.785 669.087 605.065 667.932 604.931 666.786C604.797 665.64 604.257 664.581 603.409 663.799C602.459 662.913 601.227 662.393 599.93 662.33C598.633 662.267 597.356 662.665 596.325 663.455V663.455Z"
              fill="#3F3D56"
            />
            <path
              id="Vector_20"
              d="M635.929 628.843C636.224 628.408 636.388 627.898 636.401 627.372C636.401 624.272 630.291 622.117 622.732 622.117C615.172 622.117 609.066 624.272 609.066 627.372C609.077 627.803 609.194 628.224 609.407 628.598L616.069 642.348C616.833 643.646 619.585 644.553 622.732 644.544C626.279 644.544 629.226 643.422 629.523 641.986L635.929 628.843Z"
              fill="#3F3D56"
            />
            <path
              id="Vector_21"
              d="M679.972 629.776C675.912 628.107 668.524 628.194 665.193 629.776C664.689 629.986 664.2 630.229 663.729 630.506L644.51 641.241C643.203 642.091 643.511 643.216 645.242 643.917C647.517 644.705 649.981 644.759 652.289 644.072L677.742 636.513C678.48 636.325 679.207 636.099 679.92 635.836C683.929 634.498 683.977 631.473 679.972 629.776Z"
              fill="#3F3D56"
            />
            <path
              id="Vector_22"
              d="M705.161 643.801C705.161 642.131 700.402 640.793 696.87 641.143L672.551 644.34C671.098 644.543 670.018 645.016 670.018 645.765C670.018 646.513 671.603 647.034 673.269 647.034H699.048C702.379 647.034 705.161 645.927 705.161 643.801Z"
              fill="#3F3D56"
            />
            <path
              id="Vector_23"
              d="M574.007 644.34L549.322 641.143C545.779 640.793 541.159 642.208 541.159 643.879C541.159 645.55 543.897 647.041 547.144 647.041H572.923C574.717 647.041 576.383 646.549 576.383 645.771C576.383 644.993 575.614 644.543 574.007 644.34Z"
              fill="#3F3D56"
            />
            <path
              id="Vector_24"
              d="M601.159 643.913C602.96 643.202 603.172 642.127 601.891 641.259L582.657 630.585C582.19 630.303 581.7 630.06 581.193 629.857C576.409 628.193 571.202 628.193 566.418 629.857C566.248 629.924 566.083 629.994 565.925 630.067C562.299 631.723 562.52 634.315 566.418 635.856C567.134 636.131 567.868 636.357 568.615 636.533L594.075 644.06C596.394 644.749 598.87 644.697 601.159 643.913V643.913Z"
              fill="#3F3D56"
            />
            <path
              id="Vector_25"
              d="M598.999 534.284V541.394C598.995 544.636 597.757 547.755 595.535 550.116C593.313 552.477 590.275 553.903 587.039 554.104L450.339 562.434C448.618 562.54 446.893 562.294 445.27 561.712C443.646 561.131 442.158 560.225 440.895 559.05C439.632 557.875 438.622 556.456 437.924 554.879C437.227 553.301 436.858 551.599 436.839 549.874L436.509 520.654L519.199 519.314L598.999 534.284Z"
              fill="#3F3D56"
            />
            <path
              id="Vector_26"
              d="M576.708 569.502L515.037 537.471C514.752 537.324 514.502 537.117 514.304 536.865C514.106 536.614 513.963 536.323 513.886 536.011L500.508 482.094C499.732 478.965 500.151 475.66 501.683 472.824C503.216 469.988 505.751 467.826 508.794 466.761L512.017 465.633C512.735 465.382 513.498 465.281 514.257 465.336C515.016 465.391 515.756 465.601 516.43 465.954C517.105 466.306 517.7 466.794 518.179 467.386C518.658 467.977 519.01 468.661 519.213 469.395L532.399 516.864C532.523 517.308 532.779 517.704 533.134 517.998L583.382 559.632C583.815 559.991 584.099 560.499 584.177 561.057C584.255 561.614 584.123 562.181 583.805 562.646L579.612 568.774C579.3 569.229 578.833 569.554 578.298 569.688C577.763 569.823 577.197 569.756 576.708 569.502V569.502Z"
              fill="#FBCA5A"
            />
            <path
              id="Vector_27"
              d="M536.081 485.62C506.17 469.873 474.86 461.965 441.3 466.664C451.416 449.519 458.93 433.669 458.427 421.802C458.211 417.989 458.934 414.181 460.531 410.712C462.129 407.243 464.553 404.219 467.591 401.904C472.479 398.28 478.404 396.324 484.49 396.327V396.327C489.924 393.904 496.08 393.659 501.69 395.643C504.07 396.539 506.235 397.928 508.043 399.718C509.85 401.508 511.26 403.659 512.18 406.031C513.327 408.811 515.797 411.246 519.38 413.391C522.699 415.311 525.403 418.136 527.176 421.536C529.536 426.233 529.698 430.961 526.605 435.736C538.187 447.903 538.22 466.187 536.081 485.62Z"
              fill="#2F2E41"
            />
            <path
              id="Vector_28"
              d="M493.679 446.211C505.25 446.211 514.63 436.831 514.63 425.26C514.63 413.689 505.25 404.308 493.679 404.308C482.108 404.308 472.727 413.689 472.727 425.26C472.727 436.831 482.108 446.211 493.679 446.211Z"
              fill="#FFC9A9"
            />
            <path
              id="Vector_29"
              d="M503.656 470.655L479.212 473.149C480.524 458.857 481.04 445.67 479.212 435.736H503.656C499.014 451.552 499.421 462.825 503.656 470.655Z"
              fill="#FFC9A9"
            />
            <path
              id="Vector_30"
              d="M516.749 470.114C516.729 472.304 516.683 474.471 516.609 476.614C516.099 491.954 514.169 505.764 508.909 515.934C507.841 518.04 506.565 520.033 505.099 521.884C505.089 521.903 505.076 521.92 505.059 521.934C504.958 522.075 504.848 522.208 504.729 522.334C503.654 523.67 502.459 524.905 501.159 526.024L491.149 536.524L490.689 537.004L487.769 538.954L472.729 548.974L436.609 529.564L436.569 526.194L436.529 522.744C444.559 511.364 449.569 496.564 452.749 479.714C453.619 475.104 454.359 470.334 454.989 465.434C455.419 462.044 455.799 458.584 456.129 455.074C456.18 453.997 456.545 452.957 457.179 452.084C457.179 452.083 457.18 452.082 457.18 452.081C457.18 452.079 457.181 452.078 457.182 452.077C457.183 452.076 457.184 452.076 457.185 452.075C457.187 452.075 457.188 452.074 457.189 452.074C458.729 450.114 461.789 450.154 465.489 449.954C470.679 449.674 475.789 446.984 480.379 449.524C480.493 449.577 480.604 449.637 480.709 449.704L494.179 464.664L500.369 457.994L500.659 457.684L513.409 463.144C514.713 463.708 515.77 464.723 516.383 466.004C516.997 467.285 517.127 468.745 516.749 470.114V470.114Z"
              fill="#FBCA5A"
            />
            <path
              id="Vector_31"
              d="M488.319 543.644C488.32 546.195 487.838 548.723 486.899 551.094L483.199 560.434L450.339 562.434C449.989 562.454 449.639 562.464 449.299 562.464C445.995 562.392 442.848 561.039 440.523 558.689C438.198 556.34 436.877 553.179 436.839 549.874L436.609 529.564L436.569 526.194L448.529 531.264L460.189 525.004C460.825 524.733 461.476 524.499 462.139 524.304C463.003 524.031 463.885 523.82 464.779 523.674H464.789C465.219 523.604 465.649 523.544 466.079 523.504C471.022 523.009 475.973 524.351 479.989 527.274C482.336 528.974 484.285 531.165 485.699 533.694C485.808 533.868 485.904 534.048 485.989 534.234C486.784 535.724 487.383 537.311 487.769 538.954C488.133 540.491 488.318 542.065 488.319 543.644V543.644Z"
              fill="#2F2E41"
            />
            <path
              id="Vector_32"
              opacity="0.2"
              d="M478.963 483.874L483.334 503.929L509.891 526.775L486.446 502.831L478.963 483.874Z"
              fill="black"
            />
            <path
              id="Vector_33"
              opacity="0.2"
              d="M454.52 493.352L462.501 525.777L485.947 534.258L463.998 523.283L454.52 493.352Z"
              fill="black"
            />
            <path
              id="Vector_34"
              d="M518.871 426.507C503.429 419.403 485.971 417.377 470.483 425.011L472.478 407.052L506.399 401.565L518.871 426.507Z"
              fill="#2F2E41"
            />
            <path
              id="Vector_35"
              d="M548.393 569.093C547.476 569.95 546.374 570.583 545.173 570.944C543.971 571.304 542.703 571.382 541.466 571.17C540.229 570.959 539.058 570.464 538.045 569.725C537.031 568.986 536.202 568.023 535.622 566.91L528.099 552.466L531.092 545.981L547.22 556.302C548.264 556.97 549.141 557.868 549.785 558.927C550.429 559.985 550.823 561.177 550.936 562.411C551.049 563.645 550.878 564.888 550.438 566.046C549.997 567.204 549.297 568.246 548.393 569.093V569.093Z"
              fill="#FFC9A9"
            />
            <path
              id="Vector_36"
              d="M525.826 554.537L464.154 522.506C463.87 522.358 463.62 522.152 463.422 521.9C463.223 521.648 463.081 521.357 463.004 521.046L449.626 467.129C448.849 464 449.269 460.694 450.801 457.859C452.334 455.023 454.869 452.861 457.912 451.796L461.135 450.668C461.853 450.417 462.615 450.315 463.375 450.37C464.134 450.426 464.874 450.636 465.548 450.988C466.223 451.341 466.818 451.828 467.297 452.42C467.775 453.012 468.127 453.696 468.331 454.429L481.517 501.899C481.64 502.343 481.897 502.739 482.252 503.033L532.5 544.667C532.933 545.026 533.217 545.534 533.295 546.091C533.373 546.649 533.24 547.216 532.923 547.68L528.73 553.808C528.418 554.263 527.951 554.589 527.416 554.723C526.881 554.857 526.315 554.791 525.826 554.537V554.537Z"
              fill="#FBCA5A"
            />
          </g>
          <g id="outer-flame">
            <path
              id="Fire"
              d="M672.893 644.495C672.893 684.258 650.732 684.993 623.395 684.993C596.058 684.993 551.177 677.127 573.898 644.495C597.861 610.078 638.972 544.474 623.395 541C623.395 541 672.893 604.732 672.893 644.495Z"
              fill="#F9A825"
            />
          </g>
          <g id="marshmallow">
            <path
              id="Vector_37"
              d="M645.315 580.314L531.768 560.808L533.16 553.948L645.315 580.314Z"
              fill="#3F3D56"
            />
            <path
              id="Vector_38"
              d="M633.076 583.865C633.621 583.972 634.186 583.859 634.648 583.551C635.11 583.242 635.432 582.764 635.543 582.22L636.681 576.525C636.79 575.98 636.678 575.414 636.369 574.951C636.061 574.489 635.581 574.168 635.036 574.059L618.555 570.764C618.009 570.656 617.444 570.769 616.982 571.077C616.519 571.386 616.198 571.864 616.088 572.409L614.95 578.104C614.896 578.373 614.896 578.651 614.949 578.921C615.003 579.192 615.109 579.448 615.262 579.678C615.414 579.907 615.611 580.103 615.839 580.256C616.068 580.41 616.325 580.516 616.595 580.57L633.076 583.865Z"
              fill="#E6E6E6"
            />
          </g>
          <g id="inner-flame">
            <path
              id="Fire_2"
              opacity="0.5"
              d="M645.414 647.084C645.414 662.824 636.642 663.115 625.821 663.115C615 663.115 597.234 660.001 606.228 647.084C615.714 633.461 631.987 607.493 625.821 606.118C625.821 606.118 645.414 631.345 645.414 647.084Z"
              fill="#FF6584"
            />
          </g>
        </g>
      </svg>
    </div>
  );
};

export default Camping;
