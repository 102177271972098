import React from "react";
import "./Walking.css";

const Walking = () => {
  return (
    <div className="walking-svg">
      <svg
        width="100%"
        height="500"
        viewBox="0 0 814 792"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="undraw_hiking_re_k0bc 1" clip-path="url(#clip0_2_2)">
          <g id="hiking-scene">
            <path
              id="Vector"
              d="M718.658 687.494C722.18 687.494 725.036 684.638 725.036 681.115C725.036 677.592 722.18 674.737 718.658 674.737C715.135 674.737 712.279 677.592 712.279 681.115C712.279 684.638 715.135 687.494 718.658 687.494Z"
              fill="#FFAEAE"
              fill-opacity="0.9"
            />
            <path
              id="Vector_2"
              d="M719.42 691.857H717.62V704.46H719.42V691.857Z"
              fill="#3F3D56"
            />
            <path
              id="Vector_3"
              d="M269.754 379.079C374.433 379.079 459.293 294.219 459.293 189.539C459.293 84.8597 374.433 0 269.754 0C165.074 0 80.2143 84.8597 80.2143 189.539C80.2143 294.219 165.074 379.079 269.754 379.079Z"
              fill="#514E32"
            />
            <path
              id="Vector_4"
              d="M280.01 717.56L260.65 712.13L260.69 710.05L266.49 394.77L266.78 379.05L266.79 378.59L267.8 323.51L269.27 243.67V243.66L270.27 189.54H270.8L271.82 248.17L273.15 324.48L274.1 378.63V379.03L274.14 381.26V381.27L275.98 486.82L276.17 497.31L279.97 715.46L280.01 717.56Z"
              fill="#3F3D56"
            />
            <path
              id="Vector_5"
              d="M300.42 473.951L269.088 490.447L273.455 498.74L304.786 482.245L300.42 473.951Z"
              fill="#3F3D56"
            />
            <path
              id="Vector_6"
              d="M813.31 729.49L812.76 731.42L644.93 683.62L518.5 711.49L356.81 739.11L356.59 739.05L280.01 717.56L260.65 712.13L201.52 695.53L152.2 706.52L137.78 709.73L106.43 716.72L106 714.77L137.81 707.68L152.17 704.48L201.58 693.47L201.82 693.53L260.69 710.05L279.97 715.46L356.91 737.06L518.11 709.53L644.99 681.56L645.24 681.62L813.31 729.49Z"
              fill="#AEA97A"
            />
            <path
              id="Vector_7"
              d="M398.156 752.578L407.935 756.211L426.602 720.221L412.169 714.859L398.156 752.578Z"
              fill="#361500"
            />
            <path
              id="Vector_8"
              d="M396.847 748.458L416.106 755.613L416.107 755.613C419.361 756.823 422.002 759.276 423.449 762.433C424.895 765.589 425.028 769.191 423.819 772.446L423.671 772.845L392.139 761.13L396.847 748.458Z"
              fill="#171623"
            />
            <path
              id="Vector_9"
              d="M494.392 762.733L504.824 762.732L509.787 722.494L494.39 722.495L494.392 762.733Z"
              fill="#361500"
            />
            <path
              id="Vector_10"
              d="M492.582 759.327L513.126 759.326H513.127C516.599 759.326 519.929 760.706 522.385 763.161C524.84 765.616 526.22 768.946 526.22 772.418V772.844L492.582 772.845L492.582 759.327Z"
              fill="#171623"
            />
            <path
              id="Vector_11"
              d="M446.63 483.664L450.034 476.856L478.965 491.322L478.114 497.278L483.645 504.511C483.645 504.511 493.005 509.617 491.304 526.635L489.602 542.803L487.474 563.65L502.365 615.131L510.024 683.205L515.555 738.089L488.751 736.813L464.074 647.466L420.677 743.62L390.895 735.962L431.739 640.659L436.845 600.665C436.845 600.665 433.441 579.392 449.608 564.927L447.056 557.268V547.831L438.546 502.809L446.63 483.664Z"
              fill="#0C0682"
            />
            <path
              id="Vector_12"
              opacity="0.2"
              d="M445.835 580.959L444.873 581.23L463.593 647.602L464.555 647.33L445.835 580.959Z"
              fill="black"
            />
            <path
              id="Vector_13"
              d="M447.481 567.905H489.176V563.65H447.481V567.905Z"
              fill="#3F3D56"
            />
            <path
              id="Vector_14"
              d="M489.176 570.458C490.116 570.458 490.878 568.553 490.878 566.203C490.878 563.853 490.116 561.948 489.176 561.948C488.236 561.948 487.474 563.853 487.474 566.203C487.474 568.553 488.236 570.458 489.176 570.458Z"
              fill="#3F3D56"
            />
            <path
              id="Vector_15"
              d="M490.275 463.636C496.284 452.644 492.244 438.863 481.253 432.854C470.261 426.846 456.48 430.885 450.471 441.877C444.463 452.869 448.502 466.65 459.494 472.659C470.485 478.667 484.267 474.628 490.275 463.636Z"
              fill="#361500"
            />
            <path
              id="Vector_16"
              d="M435.665 444.659C446.747 444.659 455.731 435.676 455.731 424.594C455.731 413.512 446.747 404.528 435.665 404.528C424.583 404.528 415.599 413.512 415.599 424.594C415.599 435.676 424.583 444.659 435.665 444.659Z"
              fill="#2F2E41"
            />
            <path
              id="Vector_17"
              d="M410.201 422.444C409.41 425.103 409.178 427.897 409.521 430.65C409.864 433.402 410.773 436.054 412.193 438.437C413.612 440.821 415.51 442.884 417.767 444.497C420.024 446.109 422.591 447.237 425.306 447.807C428.02 448.378 430.824 448.38 433.539 447.812C436.254 447.245 438.822 446.12 441.081 444.51C443.34 442.9 445.241 440.839 446.663 438.458C448.085 436.076 448.998 433.425 449.344 430.673C447.906 435.5 444.706 439.61 440.378 442.187C436.051 444.764 430.912 445.619 425.983 444.583C421.054 443.547 416.695 440.695 413.772 436.594C410.848 432.493 409.573 427.442 410.201 422.444Z"
              fill="#2F2E41"
            />
            <path
              id="Vector_18"
              d="M489.473 446.459C490.062 451.646 488.923 456.882 486.234 461.356C483.545 465.831 479.456 469.293 474.599 471.207C469.743 473.121 464.391 473.38 459.372 471.944C454.354 470.508 449.949 467.456 446.841 463.263C443.732 459.069 442.094 453.967 442.179 448.748C442.264 443.528 444.068 438.483 447.312 434.393C450.556 430.303 455.058 427.397 460.121 426.126C465.184 424.854 470.525 425.288 475.316 427.36C479.279 424.164 484.191 425.154 488.185 428.527C492.702 432.341 494.043 434.96 497.545 441.232C494.101 441.715 492.917 445.976 489.473 446.459Z"
              fill="#2F2E41"
            />
            <path
              id="Vector_19"
              d="M406.099 514.67C406.353 521.392 406.856 534.623 406.856 535.298C406.856 536.01 416.886 556.779 421.22 565.717V565.722C421.528 566.352 421.804 566.923 422.049 567.426C422.384 568.12 422.911 568.703 423.568 569.106C424.224 569.509 424.982 569.716 425.752 569.701L444.69 569.361C445.731 569.344 446.724 568.924 447.463 568.191C448.203 567.458 448.63 566.468 448.656 565.427L450.768 482.112C450.775 481.837 450.727 481.564 450.627 481.308C450.527 481.052 450.376 480.819 450.184 480.622C449.993 480.426 449.763 480.269 449.51 480.162C449.257 480.055 448.985 480 448.71 480H424.288C423.257 480 422.246 480.292 421.372 480.84C420.499 481.388 419.797 482.172 419.348 483.1L407.626 507.35C406.527 509.629 406.003 512.142 406.099 514.67V514.67Z"
              fill="#87FFD4"
            />
            <path
              id="Vector_20"
              d="M448.985 559.71C453.845 556.765 458.211 553.075 461.925 548.774C470.554 539.069 475.801 526.827 476.88 513.886C477.843 502.814 475.646 494.583 470.35 489.423C463.619 482.864 454.456 483.817 454.069 483.861L453.659 483.908L447.451 489.161L449.208 491.237L454.762 486.537C456.463 486.452 463.404 486.433 468.474 491.393C473.142 495.958 475.058 503.447 474.171 513.651C473.144 525.972 468.16 537.631 459.963 546.887C456.427 550.978 452.281 554.499 447.673 557.328L448.985 559.71Z"
              fill="#50E9B2"
            />
            <path
              id="Vector_21"
              d="M413.746 528.057L413.563 528.945L446.652 535.741L446.835 534.853L413.746 528.057Z"
              fill="#3F3D56"
            />
            <path
              id="Vector_22"
              d="M435.555 479.982L433.406 532.632L434.311 532.669L436.461 480.019L435.555 479.982Z"
              fill="#3F3D56"
            />
            <path
              id="Vector_23"
              d="M406.099 514.67C406.353 521.392 406.856 534.623 406.856 535.298C406.856 536.01 416.886 556.779 421.22 565.717L413.143 518.818C412.83 517.013 412.044 515.324 410.865 513.923C409.685 512.522 408.155 511.46 406.43 510.845C406.158 512.101 406.047 513.386 406.099 514.67Z"
              fill="#3F3D56"
            />
            <path
              id="Vector_24"
              d="M455.725 612.959C455.009 612.037 454.505 610.97 454.247 609.832C453.989 608.695 453.983 607.514 454.231 606.374C454.478 605.235 454.973 604.163 455.679 603.234C456.385 602.306 457.287 601.544 458.32 601.002L456.678 582.843L467.47 579.153L469.428 604.831C470.224 606.626 470.334 608.651 469.736 610.522C469.139 612.393 467.876 613.98 466.187 614.981C464.497 615.983 462.499 616.331 460.571 615.958C458.643 615.585 456.919 614.518 455.725 612.959Z"
              fill="#361500"
            />
            <path
              id="Vector_25"
              d="M448.661 503.359C448.45 500.601 449.164 497.852 450.689 495.544C452.213 493.237 454.463 491.503 457.083 490.616C459.393 489.854 461.887 489.856 464.195 490.623C466.666 491.464 468.809 493.061 470.322 495.187C471.836 497.313 472.642 499.861 472.629 502.471L473.438 594.694L454.315 595.387L448.661 503.359Z"
              fill="#EDB31E"
            />
          </g>
          <g id="grass">
            <path
              id="Vector_26"
              d="M722.259 699.89C722.259 699.89 722.88 686.864 735.625 688.378L722.259 699.89Z"
              fill="#AEA97A"
            />
            <path
              id="Vector_27"
              d="M644.22 682.34V624.166C644.22 624.166 666.891 665.409 644.22 682.34Z"
              fill="#AEA97A"
            />
            <path
              id="Vector_28"
              d="M645.62 682.33L602.766 642.989C602.766 642.989 648.479 654.179 645.62 682.33Z"
              fill="#AEA97A"
            />
            <path
              id="Vector_29"
              d="M201.22 696.34V638.166C201.22 638.166 223.891 679.409 201.22 696.34Z"
              fill="#AEA97A"
            />
            <path
              id="Vector_30"
              d="M202.62 696.33L159.766 656.989C159.766 656.989 205.479 668.179 202.62 696.33Z"
              fill="#AEA97A"
            />
            <path
              id="Vector_31"
              d="M355.22 738.34V666C355.22 666 383.411 717.286 355.22 738.34Z"
              fill="#AEA97A"
            />
            <path
              id="Vector_32"
              d="M356.961 738.327L303.672 689.406C303.672 689.406 360.517 703.322 356.961 738.327Z"
              fill="#AEA97A"
            />
          </g>
          <path
            id="bird5"
            d="M513.051 121.437L525.846 111.203C515.906 110.107 511.822 115.528 510.151 119.818C502.385 116.594 493.932 120.82 493.932 120.82L519.532 130.114C518.241 126.664 515.992 123.654 513.051 121.437Z"
            fill="#3F3D56"
          />
          <path
            id="bird4"
            d="M728.119 266.377L740.914 256.143C730.974 255.047 726.89 260.468 725.219 264.758C717.453 261.534 709 265.76 709 265.76L734.6 275.053C733.309 271.604 731.06 268.594 728.119 266.377Z"
            fill="#3F3D56"
          />
          <path
            id="bird3"
            d="M485.051 312.437L497.846 302.203C487.906 301.107 483.822 306.528 482.151 310.818C474.385 307.594 465.932 311.82 465.932 311.82L491.532 321.114C490.241 317.664 487.992 314.654 485.051 312.437Z"
            fill="#3F3D56"
          />
          <path
            id="bird2"
            d="M43.0512 117.437L55.8461 107.203C45.9063 106.107 41.8223 111.528 40.1508 115.818C32.3855 112.594 23.932 116.82 23.932 116.82L49.5321 126.114C48.2406 122.664 45.9925 119.654 43.0512 117.437Z"
            fill="#3F3D56"
          />
          <path
            id="bird1"
            d="M748.119 63.3767L760.914 53.1433C750.974 52.0467 746.89 57.4676 745.219 61.7583C737.453 58.5338 729 62.7597 729 62.7597L754.6 72.0534C753.309 68.6039 751.06 65.594 748.119 63.3767Z"
            fill="#3F3D56"
          />
        </g>
        <defs>
          <clipPath id="clip0_2_2">
            <rect width="813.31" height="791.716" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

export default Walking;
